<template>
  <div class="mod-home">
    <div class="home-title">
      <div class="text-title" style="">心狗个人健康平台</div>
      <div class="text-home">
        <span class="text-home-sub1">
          心狗理念
        </span>
        <span class="text-home-sub2">
          健康中国——为百姓健康保驾护航
        </span>
      </div>

    </div>

    <br />
    <p style="font-size: 25px; font-weight: bold;">尊敬的用户：{{ this.$store.state.personal_module.username }}</p>
    <p style="font-size: 25px; text-indent: 50px">
      您好,欢迎来到心狗个人健康平台！
      您上一次登录时间是{{ lastlogin_process }}。
    </p>
    <p style="font-size: 25px; text-indent: 50px">
      请经常登陆心狗健康管理系统查看您的健康信息。</p>
    <p style="font-size: 25px; font-weight: bold;">我的健康周报</p>
    <router-view></router-view>

  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      lastlogin_process: '',
    }
  },
  created() {

  },
  mounted() {
    this.lastLoginProcess()
  },

  methods: {
    toHealthWeekly() {
      this.$router.push({ name: 'HealthReportWeekly' })
    },
    lastLoginProcess() {
      // 修改了上次登录时间差8小时的问题
      var tempdate = new Date(this.$store.state.personal_module.last_login)
      var lastlogin = tempdate
      // lastlogin.setHours(tempdate.getHours() + 8)
      lastlogin.setHours(tempdate.getHours())
      let y = lastlogin.getFullYear();
      let MM = lastlogin.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = lastlogin.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = lastlogin.getHours();
      h = h < 10 ? ('0' + h) : h;
      let m = lastlogin.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let s = lastlogin.getSeconds();
      s = s < 10 ? ('0' + s) : s;
      this.lastlogin_process = y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;  //显示到秒
    }
  },


}
</script>

<style lang="scss" scoped>
.mod-home {
  line-height: 1;
}

.home-title {
  background-image: linear-gradient(45deg, #830707, #b30808);
  padding-top: 15px;
  padding-bottom: 15px;

  .text-title {
    font-size: 40px;
    text-align: center;
    color: #ffffff;
    padding-bottom: 20px;
    font-weight: bold;
  }

  .text-home {
    text-align: center;
    font-size: 28px;
    color: rgb(255, 255, 255);

    .text-home-sub1 {
      color: #830707;
      background-color: #ffffff;
      font-weight: bold;
      border-radius: 4px;
      padding-left: 5px;
    }

    .text-home-sub2 {
      padding-left: 15px;
    }
  }
}

</style>
